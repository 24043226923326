import "./styles.css";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Doors from "./routes/Doors";
import NewDoor from "./routes/NewDoors"
import FAQ from "./routes/FAQ";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Laminate from "./routes/Laminate";
import Facebook from "./routes/Facebook";
import Instagram from "./routes/Instagram";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/doors" element={<Doors />} />
        <Route path="/newdoors" element={<NewDoor />} />
        <Route path="/laminate" element={<Laminate />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/facebook" element={<Facebook />} />
        <Route path="/instagram" element={<Instagram />} />
      </Routes>
    </div>
  );
}

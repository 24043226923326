import React, { useState } from "react";
import "./Door.css"
import CloseIcon from "@material-ui/icons/Close"
import { data } from "../components/DoorData"
import video from "../src_stefko/vrati/chupeshta.mp4","../src_stefko/vrati/nova.mp4","../src_stefko/vrati/dostena.mp4"

const Gallery = () => {

    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    };

    function hidenav() {
        var navbar = document.querySelector('.NavbarItems');
        navbar.style.display = "none";
    }

    function shownav() {
        var navbar = document.querySelector('.NavbarItems');
        navbar.style.display = "flex";
    }


    var modal = document.querySelector('.model');
    window.onclick = function (event) {
        if (event.target === modal) {
            return (
                setModel(false) & shownav()
            )
        }
    }




    return (
        <>
            <div className={model ? "model open" : "model"}>
                <img src={tempimgSrc} alt="" />
                <CloseIcon onClick={() => setModel(false) & shownav()} />
            </div>
            <div className="gallery">
                <video controls>
                    <source src={video} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                </video>
                {data.map((item, index) => {
                    return (
                        <div className="pics" key={index} onClick={() => getImg(item.imgSrc) & hidenav()} >
                            <img src={item.imgSrc} alt="" style={{ width: '100%' }} />
                        </div>
                    )
                })}
            </div>
        </>
    );
}

export default Gallery;
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import InfoDoors from "../components/InfoDoors";
import Navbar from "../components/Navbar"
import HomeImage from "../src_stefko/home.jpg"
import { Helmet } from 'react-helmet'

function Home() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Врати произведени във Варна и ламинати" />
                <meta name="keywords" content="Врати Варна,Варна,Врати,Стефко стил,ламинати,производство на врати,Vrati,Varna,Vrati Varna,Stefko stil,laminati,proizvodstvo na vrati" />
            </Helmet>
            <Navbar />
            <Hero
                cName="hero"
                heroImg={HomeImage}
                title="Вратите през които искате да минете!"
                text="Вижте моделите които предлагаме"
                buttonText="Виж повече"
                url="/doors"
                btnClass="show"
            />
            <InfoDoors />
            <Footer />
        </>
    );
}

export default Home;
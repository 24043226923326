import React, { useState } from "react";
import "./Laminate.css"
import CloseIcon from "@material-ui/icons/Close"
import { data } from "../components/LaminateData"


const Gallery = () => {

    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');
    const [setImagecaption] = useState('');

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    };

    const getCaption = (caption) => {
        setImagecaption(caption);
    }

    function hidenav() {
        var navbar = document.querySelector('.NavbarItems');
        navbar.style.display = "none";
    }

    function shownav() {
        var navbar = document.querySelector('.NavbarItems');
        navbar.style.display = "flex";
    }


    var modal = document.querySelector('.model');
    window.onclick = function (event) {
        if (event.target === modal) {
            return (
                setModel(false) & shownav()
            )
        }
    }




    return (
        <>
            <div className="about-container">
                <h3>My Floor</h3>
                <p>Немски ламиниран паркет с високо качество на марка My Floor специално разработен в пет серии - Villa 12мм, Cottage 8мм, Lodge 8мм, Chalet 10мм, Residence 10мм. Изключително лесни за поставяне, ламинираните паркети на My Floor използват универсалната click система. Особена специфика при моделите е високото им ниво на устойчивост, изработени са от HDF (ПДВ - Пресовани Дървени Влакна) - най-здравия материал предлаган на пазара за ламиниран паркет. Ламинираният паркет е отлично решение за помещения на офиси и къщи, за всекидневни и детски стаи.</p>
                <h3>AGT</h3>
                <p>AGT е един от първите производители на MDF в Турция. Създаден от водещите дизайнери на компанията, AGT създават богатa серия модели, които съчетават естествени дървесни ивици придаващи автентичност с модерно излъчване. Лесен за инсталиране, благодарение на патентованата си клик система за монтаж, което означава, че не са необходими лепила. Ламинатът на турският производител има 31, 32, 33 клас на износоустойчивост и различни дебелини като е по-подходящ за места с по-нисък трафик. Може да се използва в системата "топъл под". Получавате не само естетически характеристики, но и експлоатационни на особено разумна цена.</p>
                <hr />
            </div>

            <div className={model ? "model open" : "model"}>
                <img src={tempimgSrc} alt="" />
                <CloseIcon onClick={() => setModel(false) & shownav()} />
            </div>
            <div className="gallery">
                {data.map((item, index) => {
                    return (
                        <div className="pics" key={index} onClick={() => getImg(item.imgSrc) & hidenav() & getCaption(item.caption)} >
                            <img src={item.imgSrc} alt="" style={{ width: '100%' }} />
                            <p>{item.caption}</p>
                        </div>
                    )
                })}
            </div>
        </>
    );
}

export default Gallery;
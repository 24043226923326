import InfoDoorsData from "./InfoDoorsData";
import "./InfoDoorsStyles.css"
import InteriorClassic from "../src_stefko/interiorclassic.jpg"
import InteriorClassic2 from "../src_stefko/interiorclassic2.jpg"
import InteriorDekor from "../src_stefko/interiordekor.jpg"
import InteriorDekor2 from "../src_stefko/interiordekor2.jpg"
import InteriorStil from "../src_stefko/interiorstil.jpg"
import InteriorStil2 from "../src_stefko/interiorstil2.jpg"
import ExteriorImage from "../src_stefko/exterior.jpg"
import ExteriorImage2 from "../src_stefko/exterior1.jpg"
const InfoDoors = () => {
    return (
        <div className="infodoors">
            <h1>Видове предлагани врати</h1><br />
            <p>Широка гама от интериорни и екстериорни врати</p>

            <InfoDoorsData
                className="interior-doors"
                heading="Интериорни врати Класик"
                text="В галерия 'Класик' представяме висококачествен МДФ с покритие от естествени фурнири, които са оцветени с байцове и трикомпонентни полиуретанови. Видовете фурнира са: дъб,бук и за по-изискан стил файн лайн. Допълнителното остъкляване е без ограничение по форма и размери. Това ги прави подходящи за изискания стил на вашия дом,офис или вила."

                img1={InteriorClassic}
                img2={InteriorClassic2}
            />

            <InfoDoorsData
                className="interior-doors-reverse"
                heading="Интериорни врати Декор"
                text="Вратите в галерията 'Декор' могат да бъдат гладки щамповани с фрезован или нефрезован орнамент. Повърхностно са обработени с водоустойчив, трикомпонентен полиуретанови бои в над 1800 цвята, което ги прави подходящи за мокри помещения. Вратите Декор дават пълна свобода при остъкляването, както и разнообразни комбинации в цветово отношение."

                img1={InteriorDekor}
                img2={InteriorDekor2}
            />

            <InfoDoorsData
                className="interior-doors"
                heading="Интериорни врати Стил"
                text="В галерия 'Стил' може да разгледате врати, които са изработени от висококачествен МДФ с ламинирано покритие, което е устойчиво на триене и механично увреждане на повърхността. Вратите с този вид покритие ги прави подходящи най-вече за офиси, административни сгради, хотели, училища и магазини"

                img1={InteriorStil}
                img2={InteriorStil2}
            />

            <InfoDoorsData
                className="interior-doors-reverse"
                heading="Екстериорни врати"
                text="Екстериорните врати могат да бъдат изработени от всички видове МДФ, които предлагаме. Те са с метална рамка, с дебелина на крилото 7см и тристранно заключване. Врата от този вид осигурява сигурност и защита на вашия дом или офис."

                img1={ExteriorImage}
                img2={ExteriorImage2}
            />

        </div>
    );
};

export default InfoDoors
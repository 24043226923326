import "./Contacts.css"

function CheckWorking() {
    var myDate = new Date()
    if (myDate.getDay() === 6 || myDate.getDay() === 0 || myDate.getHours() >= 18 || myDate.getHours() <= 9) {
        return <div className="red">Затворено!</div>
    }
    else {
        return <div className="green">Отворено!</div>
    }
}

function ContactData(props) {

    return (
        <div className="c-card">
            <div className="c-image">
                <img src={props.image} alt="cimage" />
            </div>

            <h4>{props.heading}</h4>

            <i class="fa-sharp fa-solid fa-location-arrow">
                <a href="https://www.google.com/maps/place/Врати+Стефко+Стил/@43.2157962,27.9028627,17z/data=!3m1!4b1!4m5!3m4!1s0x40a455eab4dfcbe9:0x6ebd47a600307799!8m2!3d43.2157923!4d27.9050514">{props.address1}</a>
                <a href="https://www.google.com/maps/@43.2276397,27.917528,3a,75y,357.86h,83.78t/data=!3m6!1e1!3m4!1sXFpH9x6sqJthMcyNqW6H7g!2e0!7i16384!8i8192">{props.address2}</a>
            </i>

            <p>{props.workingtime}{CheckWorking()}</p>

            <i class="fa-sharp fa-solid fa-phone">
                <a href="tel:+359 897 946 911">{props.phone1}</a>
                <a href="tel:+359 897 946 904">{props.phone2}</a>
                <a href="tel:+359 896 086 096">{props.phone3}</a>
            </i>

            <i class="fa-sharp fa-solid fa-envelope">
                <a href="mailto:stefko.stil@gmail.com">{props.email}</a>
            </i>

            <h3>{props.come}</h3>

            <a href={props.url} className={props.btnClass}>{props.buttonText}</a>
        </div>
    )
}

export default ContactData;
export const faqs = [
    {
        id: 1,
        question: "Мога ли да избера посокота, в която да се отваря интериорната врата?",
        answer: "При вземането на размери се определя накъде ще се отваря вратата."
    },
    {
        id: 2,
        question: "От какъв материал са направени интериорните врати?",
        answer: "Интериорните врати, които предлагаме, са изработени от Hight Destiny Fiberboard (HDF) плоскости. Това са високоплътностни плоскости от дървесни частици, пресовани и премесени с лепило и естествена смола за по-голяма якост."
    },
    {
        id: 3,
        question: "Интериорните врати подходящи ли са за баня?",
        answer: "Интериорните врати за баня са покрити от вътрешната страна с полиестерна боя, което означава че те са влагоустойчиви, но не и водоустойчиви. Ако банята е по-широка или има преграда за водата, тогава няма опасност от деформация на вратите. Ако те се обливат директно с вода, тогава е по-добре да поръчате врата за баня."
    },
    {
        id: 4,
        question: "Възможно ли е да бъде подменено стъкло на интериорна врата?",
        answer: "Да, стъклата при интериорните врати са цели и подмяната им е много лесна, с изключение на триплексовите врати там се прави ново крило."
    },
    {
        id: 5,
        question: "Изработвате ли врати по размери на клиента?",
        answer: "Да, ние сме производители на врати и можем да изработим всяка врата индивидуално според нуждите и вкуса на клиентите ни."
    },
    {
        id: 6,
        question: "В посочените цени включен ли е и монтаж?",
        answer: "Да, монтажа е включен"
    },
    {
        id: 7,
        question: "Предлагате ли вземане на размери преди монтажа?",
        answer: "Предлагаме вземане на размери,което е гаранция за точния избор на най-подходящоте врати."
    },
    {
        id: 8,
        question: "Как се определя цената на интериорните врати?",
        answer: "Цената на интериорните врати е различна в зависимост от модела, от неговото покритие, вида на бравата и т.н, и се определя в нашите магазини"
    }
]


import Img1 from "../src_stefko/vrati/1.jpg"
import Img2 from "../src_stefko/vrati/2.jpg"
import Img3 from "../src_stefko/vrati/3.jpg"
import Img4 from "../src_stefko/vrati/4.jpg"
import Img5 from "../src_stefko/vrati/5.jpg"
import Img6 from "../src_stefko/vrati/6.jpg"
import Img7 from "../src_stefko/vrati/7.jpg"
import Img8 from "../src_stefko/vrati/8.jpg"
import Img9 from "../src_stefko/vrati/9.jpg"
import Img10 from "../src_stefko/vrati/10.jpg"
import Img11 from "../src_stefko/vrati/11.jpg"
import Img12 from "../src_stefko/vrati/12.jpg"
import Img13 from "../src_stefko/vrati/13.jpg"
import Img14 from "../src_stefko/vrati/14.jpg"
import Img15 from "../src_stefko/vrati/15.jpg"
import Img16 from "../src_stefko/vrati/16.jpg"
import Img17 from "../src_stefko/vrati/17.jpg"
import Img18 from "../src_stefko/vrati/18.jpg"
import Img19 from "../src_stefko/vrati/19.jpg"
import Img20 from "../src_stefko/vrati/20.jpg"
import Img21 from "../src_stefko/vrati/21.jpg"
import Img22 from "../src_stefko/vrati/22.jpg"
import Img23 from "../src_stefko/vrati/23.jpg"
import Img24 from "../src_stefko/vrati/24.jpg"
import Img25 from "../src_stefko/vrati/25.jpg"
import Img26 from "../src_stefko/vrati/26.jpg"
import Img27 from "../src_stefko/vrati/27.jpg"
import Img28 from "../src_stefko/vrati/28.jpg"
import Img29 from "../src_stefko/vrati/29.jpg"
import Img30 from "../src_stefko/vrati/30.jpg"
import Img31 from "../src_stefko/vrati/31.jpg"
import Img32 from "../src_stefko/vrati/32.jpg"
import Img33 from "../src_stefko/vrati/33.jpg"
import Img34 from "../src_stefko/vrati/34.jpg"
import Img35 from "../src_stefko/vrati/35.jpg"
import Img36 from "../src_stefko/vrati/36.jpg"
import Img37 from "../src_stefko/vrati/37.jpg"
import Img38 from "../src_stefko/vrati/38.jpg"
import Img39 from "../src_stefko/vrati/39.jpg"
import Img40 from "../src_stefko/vrati/40.jpg"
import Img41 from "../src_stefko/vrati/41.jpg"
import Img42 from "../src_stefko/vrati/42.jpg"
import Img43 from "../src_stefko/vrati/43.jpg"
import Img44 from "../src_stefko/vrati/44.jpg"
import Img45 from "../src_stefko/vrati/45.jpg"
import Img46 from "../src_stefko/vrati/46.jpg"
import Img47 from "../src_stefko/vrati/47.jpg"
import Img48 from "../src_stefko/vrati/48.jpg"
import Img49 from "../src_stefko/vrati/49.jpg"
import Img50 from "../src_stefko/vrati/50.jpg"


export let data = [
    {
        id: 1,
        imgSrc: Img1,
    },
    {
        id: 2,
        imgSrc: Img2,
    },
    {
        id: 3,
        imgSrc: Img3,
    },
    {
        id: 4,
        imgSrc: Img4,
    },
    {
        id: 5,
        imgSrc: Img5,
    },
    {
        id: 6,
        imgSrc: Img6,
    },
    {
        id: 7,
        imgSrc: Img7,
    },
    {
        id: 8,
        imgSrc: Img8,
    },
    {
        id: 9,
        imgSrc: Img9,
    },
    {
        id: 10,
        imgSrc: Img10,
    },
    {
        id: 11,
        imgSrc: Img11,
    },
    {
        id: 12,
        imgSrc: Img12,
    },
    {
        id: 13,
        imgSrc: Img13,
    },
    {
        id: 14,
        imgSrc: Img14,
    },
    {
        id: 15,
        imgSrc: Img15,
    },
    {
        id: 16,
        imgSrc: Img16,
    },
    {
        id: 17,
        imgSrc: Img17,
    },
    {
        id: 18,
        imgSrc: Img18,
    },
    {
        id: 19,
        imgSrc: Img19,
    },
    {
        id: 20,
        imgSrc: Img20,
    },
    {
        id: 21,
        imgSrc: Img21,
    },
    {
        id: 22,
        imgSrc: Img22,
    },
    {
        id: 23,
        imgSrc: Img23,
    },
    {
        id: 24,
        imgSrc: Img24,
    },
    {
        id: 25,
        imgSrc: Img25,
    },
    {
        id: 26,
        imgSrc: Img26,
    },
    {
        id: 27,
        imgSrc: Img27,
    },
    {
        id: 28,
        imgSrc: Img28,
    },
    {
        id: 29,
        imgSrc: Img29,
    },
    {
        id: 30,
        imgSrc: Img30,
    },
    {
        id: 31,
        imgSrc: Img31,
    },
    {
        id: 32,
        imgSrc: Img32,
    },
    {
        id: 33,
        imgSrc: Img33,
    },
    {
        id: 34,
        imgSrc: Img34,
    },
    {
        id: 35,
        imgSrc: Img35,
    },
    {
        id: 36,
        imgSrc: Img36,
    },
    {
        id: 37,
        imgSrc: Img37,
    },
    {
        id: 38,
        imgSrc: Img38,
    },
    {
        id: 39,
        imgSrc: Img39,
    },
    {
        id: 40,
        imgSrc: Img40,
    },
    {
        id: 41,
        imgSrc: Img41,
    },
    {
        id: 42,
        imgSrc: Img42,
    },
    {
        id: 43,
        imgSrc: Img43,
    },
    {
        id: 44,
        imgSrc: Img44,
    },
    {
        id: 45,
        imgSrc: Img45,
    },
    {
        id: 46,
        imgSrc: Img46,
    },
    {
        id: 47,
        imgSrc: Img47,
    },
    {
        id: 48,
        imgSrc: Img48,
    },
    {
        id: 49,
        imgSrc: Img49,
    },
    {
        id: 50,
        imgSrc: Img50,
    }
]
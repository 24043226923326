import Catalog from "../components/Catalog";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import DoorImage from "../src_stefko/doors.jpg"
import DoorGallery from "../components/DoorGallery"
import { Helmet } from 'react-helmet'

function Doors() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Врати Стефко Стил, Vrati Stefko Stil" />
                <meta name="keywords" content="vrati stefko stil,врати стефко стил,vrati,varna,врати,варна" />
            </Helmet>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={DoorImage}
                title="Каталог"
                btnClass="hide"
            />
            <Catalog />
            <DoorGallery />
            <Footer />
        </>
    );
}

export default Doors;
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import LaminateImg from "../src_stefko/laminat/my-floor/Dekore_CHALET_2022/M1022_1.jpg"
import LaminateGallery from "../components/LaminateGallery"
import { Helmet } from 'react-helmet'

function Laminate() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Ламинати Стефко Стил, Laminati Stefko Stil" />
                <meta name="keywords" content="Ламинати варна,Ламинати стефко стил,Laminati Varna,Laminati Stefko Stil,Ламинати,laminati" />
            </Helmet>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={LaminateImg}
                title="Ламинати"
                btnClass="hide"
            />
            <LaminateGallery />
            <Footer />
        </>
    );
}

export default Laminate;
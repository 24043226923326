import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import NewDoorImg from "../src_stefko/newdoor.jpg"
import NewDoor from "../components/NewDoor"
import { Helmet } from 'react-helmet'

function Laminate() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Нови модели Стефко Стил,Novi modeli Stefko Stil" />
                <meta name="keywords" content="нови модели врати,novi modeli vrati,нови,модели,варна,novi,modeli" />
            </Helmet>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={NewDoorImg}
                title="Нови модели врати 2023"
                btnClass="hide"
            />
            <NewDoor />
            <Footer />
        </>
    );
}

export default Laminate;
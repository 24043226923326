import { createRoot } from "react-dom/client";
import { BrowserRouter, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

root.render(
  <BrowserRouter>
    <Wrapper>
      <App />
    </Wrapper>
  </BrowserRouter>
);

import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import AboutImage from "../src_stefko/about.jpg"
import { Helmet } from 'react-helmet'

function About() {
    return (
        <>
            <Helmet>
                <meta name="description" content="За нас Стефко стил " />
                <meta name="keywords" content="За нас стефко стил, za nas stefko stil" />
            </Helmet>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={AboutImage}
                title="За нас"
                btnClass="hide"
            />
            <AboutUs />
            <Footer />
        </>
    );
}

export default About;
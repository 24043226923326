export const MenuItems = [
    {
        title: "Начало",
        url: "/",
        cName: "nav-links"
    },
    {
        title: "Врати",
        url: "/doors",
        cName: "nav-links"
    },
    {
        title: "Нови Модели",
        url: "/newdoors",
        cName: "nav-links"
    },
    {
        title: "Ламинати",
        url: "/laminate",
        cName: "nav-links"
    },
    {
        title: "Въпроси",
        url: "/faq",
        cName: "nav-links"
    },
    {
        title: "За нас",
        url: "/about",
        cName: "nav-links"
    },
    {
        title: "Контакти",
        url: "/contact",
        cName: "nav-links"
    },
    {
        title: <i className="fa-brands fa-facebook" />,
        url: "/facebook",
        cName: "nav-links"
    },
    {
        title: <i className="fa-brands fa-instagram" />,
        url: "/instagram",
        cName: "nav-links"
    }
]
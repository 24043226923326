import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import Contacts from "../components/Contacts";
import ContactImage from "../src_stefko/contact.jpg"
import { Helmet } from 'react-helmet'

function Contact() {
    return (
        <>
            <Helmet>
                <meta name="description" content="Контакти стефко стил" />
                <meta name="keywords" content="Контакти стефко стил,kontakti,kontakti stefko stil" />
            </Helmet>
            <Navbar />
            <Hero
                cName="hero-mid"
                heroImg={ContactImage}
                title="Контакти"
                btnClass="hide"
            />
            <Contacts />
            <Footer />
        </>
    );
}

export default Contact;
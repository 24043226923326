import "./FAQStyle.css"
import { faqs } from "./FaqItems";
import FAQ from "./FAQ";

function FAQs() {

    return (
        <section className="faqs">
            <div className="container faqs__container">
                <h1>Често задавани въпроси</h1>
                <div className="faqs__wrapper">
                    {
                        faqs.map(({ id, question, answer }) => {
                            return <FAQ key={id} question={question} answer={answer} />
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default FAQs
import Img1 from "../src_stefko/novivrati/1.jpg"
import Img2 from "../src_stefko/novivrati/2.jpg"
import Img3 from "../src_stefko/novivrati/3.jpg"
import Img4 from "../src_stefko/novivrati/4.jpg"
import Img5 from "../src_stefko/novivrati/5.jpg"
import Img6 from "../src_stefko/novivrati/6.jpg"
import Img7 from "../src_stefko/novivrati/7.jpg"
import Img8 from "../src_stefko/novivrati/8.jpg"
import Img9 from "../src_stefko/novivrati/9.jpg"
import Img10 from "../src_stefko/novivrati/10.jpg"
import Img11 from "../src_stefko/novivrati/11.jpg"
import Img12 from "../src_stefko/novivrati/12.jpg"
import Img13 from "../src_stefko/novivrati/13.jpg"
import Img14 from "../src_stefko/novivrati/14.jpg"



export let data = [
    {
        id: 1,
        imgSrc: Img1,
    },
    {
        id: 2,
        imgSrc: Img2,
    },
    {
        id: 3,
        imgSrc: Img3,
    },
    {
        id: 4,
        imgSrc: Img4,
    },
    {
        id: 5,
        imgSrc: Img5,
    },
    {
        id: 6,
        imgSrc: Img6,
    },
    {
        id: 7,
        imgSrc: Img7,
    },
    {
        id: 8,
        imgSrc: Img8,
    },
    {
        id: 9,
        imgSrc: Img9,
    },
    {
        id: 10,
        imgSrc: Img10,
    },
    {
        id: 11,
        imgSrc: Img11,
    },
    {
        id: 12,
        imgSrc: Img12,
    },
    {
        id: 13,
        imgSrc: Img13,
    },
    {
        id: 14,
        imgSrc: Img14,
    }
]
import "./AboutUsStyles.css"

function AboutUs() {
    return (
        <div className="about-container">
            <h1>Нашата история</h1>
            <p>Нашата фирма е създадена през 1989г. и благодарение на ефективното управление и непрекъснатите инвестиции в нови технологии, в продължение на 33 години, ние успяхме да се задържим на високо-конкурентния пазар. Опитваме се по всякакъв начин да подобряваме качеството на предлаганите от нас врати и мебели за дома, офис и вилата. Високото качество и разнообразие на предлаганите от нас цветове и модели, ни правят достоен производител както на нашия, така и на чуждестранния пазар.</p>
            <h1>Добре дошли в света на Стефко Стил ООД</h1>
            <p>Ние сме се специализирали в производството на интериорни, екстериорни врати и мебели. През времето на дългогодишната ни история сме се утвърдили на пазара като производител с доказан професионализъм,високо качество и разнообразие на предлаганата от нас продукция. Ние можем да ви предложим богат асортимент от екстериорни, интериорни врати и мебели, изработени по индивидуален проект, в различни цветове с изискан стил, подходящи за всеки дом, офис или хотел. Предлаганите от нас врати ще превърнат вашите идеи в реалност. Имате възможност да избирате от няколко вида материал, с който могат да бъдат изработени вашите перфектни модели. Ламинираното покритие на този МДФ позволява да избирате от 15 вида цветове, които да съчетават вашия вкус и интериор в едно.
                Craf master моделите биват в различна серия и при тях цветовото отношение предлага разновидности по каталожен номер, които позволява избор от 1800 цвята.
                Фурнирования МДФ - този вид МДФ предлага големи и неограничени възможности на цветовете, направени по мостра или мостра дадена ни от Вас - нашите клиенти.</p>
        </div>
    )
}

export default AboutUs
import "./Contacts.css"
import ContactData from "./ContactData"
import Store1 from "../src_stefko/store1.jpg"
import Store2 from "../src_stefko/store2.jpg"

function Contacts() {

    return (
        <div className="contact">
            <h1>Нашите магазини</h1>
            <p>Може да намерите нашите обекти чрез Google Maps</p>
            <div className="contactcard">
                <ContactData
                    image={Store1}
                    heading="Стефко Стил - Сливница"
                    address1="Варна, бул 'Сливница' / ул.'Скопие' 13"
                    workingtime="Работно време Пон-Пет 10:00-18:00 :"
                    phone1="+359 897 946 911"
                    email="stefko.stil@gmail.com"
                    come="Стигнете до нас!"
                    buttonText="Упътване"
                    url="https://www.google.com/maps/dir//Врати+Стефко+Стил,+ul.+'Skopie'+13,+9000+Varna+Center,+Varna/@43.2157962,27.9028627,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40a455eab4dfcbe9:0x6ebd47a600307799!2m2!1d27.9050514!2d43.2157923"
                    btnClass="show"
                />

                <ContactData
                    image={Store2}
                    heading="Стефко Стил - Смирненски"
                    address2="Варна, бул 'Христо Смирненски' 153"
                    workingtime="Работно време Пон-Пет 10:00-18:00,Събота 10:00-14:00:  :"
                    phone2="+359 897 946 904"
                    phone3="+359 896 086 096"
                    email="stefko.stil@gmail.com"
                    come="Стигнете до нас!"
                    buttonText="Упътване"
                    url="https://www.google.com/maps/dir//bul.+'Hristo+Smirnenski'+153,+9010+Varna/@43.2276902,27.9176359,21z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40a4545cd4743a5d:0xeb0314f0e60aaacb!2m2!1d27.9177842!2d43.2276257"
                    btnClass="show"
                />
            </div>
        </div>
    )
}

export default Contacts
import "./FooterStyles.css"
import Logo from "../src_stefko/logofooter.png"

const Footer = () => {
    return (
        <div className="footer">
            <div className="top">
                <div>
                    <img className="logo" alt="logo" src={Logo} />
                    <p>Името говори!</p>
                </div>
                <div>
                    <a href="/facebook">
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href="/instagram">
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                </div>
            </div>
            <div className="bottom">
                <div>
                    <a href="/contact"><h4>Стефко Стил - Сливница</h4></a>

                    <i class="fa-sharp fa-solid fa-location-arrow">
                        <a href="https://www.google.com/maps/place/Врати+Стефко+Стил/@43.2157962,27.9028627,17z/data=!3m1!4b1!4m5!3m4!1s0x40a455eab4dfcbe9:0x6ebd47a600307799!8m2!3d43.2157923!4d27.9050514">
                            Варна, бул 'Сливница' / ул.'Скопие' 13
                        </a>
                    </i>

                    <i class="fa-sharp fa-solid fa-phone">
                        <a href="tel:+359 897 946 911">тел: +359 897 946 911</a>
                    </i>
                </div>
                <div>
                    <a href="/contact"><h4>Стефко Стил - Смирненски</h4></a>
                    <i class="fa-sharp fa-solid fa-location-arrow">
                        <a href="https://www.google.com/maps/@43.2276397,27.917528,3a,75y,357.86h,83.78t/data=!3m6!1e1!3m4!1sXFpH9x6sqJthMcyNqW6H7g!2e0!7i16384!8i8192">
                            Варна, бул 'Х.Смирненски' 153
                        </a>
                    </i>
                    <i class="fa-sharp fa-solid fa-phone">
                        <a href="tel:+359 897 946 904">тел: +359 897 946 904</a>
                        <a href="tel:+359 896 086 096"> +359 896 086 096</a>
                    </i>
                </div>
            </div>
        </div>
    )
}

export default Footer